<template>
  <div class="container_margin">
    <!-- start of Input form -->
    <div
      v-if="permissions.includes('nexlaw.legal-argument')"
      class="nexcard p-4 mb-4"
    >
      <h3 class="text-center text-blue my-3">
        <b>{{ "Create Legal Research Memo And Opinion" }}</b>
      </h3>

      <div class="mb-3">
        <b>
          {{ "Question" }}
          <span
            id="tooltip-target-1"
            class="text-danger cursor-pointer"
          >*</span>
        </b>
        <b-tooltip
          target="tooltip-target-1"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip4"
          title="Required"
        ></b-tooltip>
        
        <b-form-textarea
          id="textarea"
          class="mt-2"
          v-model.trim="question"
          placeholder="Example: Did Loman's circular ad for designer leather coat constitute an offer and create a binding contract under the United States law?"
          autocorrect="off"
          autocomplete="off"
          rows="2"
          max-rows="2"
          v-validate="'required'"
          type="text"
          name="question"
        ></b-form-textarea>
        <small class="text-muted float-right count_length">
          {{ getWordCount(question) }} / 50
        </small>

        <!-- error displays -->
        <small
          class="text-danger text-sm"
          v-show="getWordCount(question) > 50"
        >
          {{ "Should not be more than 50 words" }}
        </small>
        <small
          class="text-danger small"
          v-show="errors.has('question')"
        >
          {{ errors.first("question") }}
        </small>
      </div>

      <div class="mb-3">
        <b>
          {{ "Findings" }}
          <span
            id="tooltip-target-2"
            class="text-danger cursor-pointer"
          >*</span>
        </b>
        <b-tooltip
          target="tooltip-target-2"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip4"
          title="Required"
        ></b-tooltip>
      
        <b-form-textarea
          id="textarea"
          class="mt-2"
          v-model.trim="findings"
          placeholder="Example: Loman's Fashions, a retailer of women's and men's outerwear, distributed a circular in November advertising a manufacturer's closeout of designer women's leather coats for $59.99, coats that regularly sold for $300.00. The ad announced that the store would open at 7 a.m. on Friday, November 30, and stated that the 'early bird catches the savings!' After about fifteen minutes, all the advertised coats had been sold."
          autocorrect="off"
          autocomplete="off"
          rows="5"
          max-rows="5"
          type="text"
          name="findings"
          v-validate="'required'"
        ></b-form-textarea>
        <small class="text-muted float-right count_length">
          {{ getWordCount(findings) }} / 500
        </small>

        <!-- error displays -->
        <small
          class="text-danger text-sm"
          v-show="getWordCount(findings) > 500"
        >
          {{ "Should not be more than 500 words" }}
        </small>
        <small
          class="text-danger small mb-2"
          v-show="errors.has('findings')"
        >
          {{ errors.first("findings") }}
        </small>
      </div>

      <b>
        {{ "Jurisdiction" }}
        <span
          id="tooltip-target-3"
          class="text-danger cursor-pointer"
        >*</span>
      </b>
      <b-tooltip
        target="tooltip-target-3"
        triggers="hover"
        placement="top"
        variant="secondary"
        id="tooltip4"
        title="Required"
      ></b-tooltip>

      <div class="row mt-2">
        <div class="col">
          <b-form-select
            class="form-select w-100"
            :class="{ 'text-muted': jurisdiction === '' }"
            id="inputGroupSelect04"
            aria-label="Example select with button addon"
            v-model="jurisdiction"
            v-validate="'required'"
            name="Country"
          >
            <b-form-select-option value="" disabled selected>
              {{ "Please Select a Country" }}
            </b-form-select-option>
            <b-form-select-option
              class="text-softblack"
              :value="item"
              :key="index"
              v-for="(item, index) in jurisdictions"
            >
              {{ item }}
            </b-form-select-option>
          </b-form-select>

          <!-- error displays -->
          <small v-show="errors.has('Country')" class="text-danger small">
            {{ "The Jurisdiction field is required" }}
          </small>
        </div>

        <div class="col">
          <button
            @click="nextStep()"
            class="btn-primary btn px-2 float-right"
          >
            {{ "Generate" }}
          </button>
        </div>
      </div>
    </div>
    <!-- end of Input form -->

    <!-- start of History header -->
    <h5><b>{{ "History" }}</b></h5>

    <div class="row mt-2 mb-3">
      <div
        v-if="searched_data.length !== 0 || searchQuery.length !== 0"
        class="col-5"
      >
        <span id="searchCaseLaw" class="input-group">
          <input
            v-model="searchQuery"
            type="search"
            class="form-control"
            placeholder="Search by statement"
            aria-label="Search by statement"
            @input="onInput"
            @keyup.enter="searchClients"
          />
          <span class="input-group-text cursor-pointer" @click="searchClients">
            <b-icon icon="search" font-scale="1.2"> </b-icon>
          </span>
        </span>
      </div>

      <div v-if="searched_data.length !== 0 && rows > 10" class="col-7">
        <b-pagination
          class="mb-0 float-right"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          last-number
        ></b-pagination>
      </div>
    </div>
    <!-- end of History header -->

    <!-- start of History table -->
    <table class="table card border shadow-sm p-2">
      <thead>
        <tr>
          <th scope="col" width="6%">{{ "ID" }}</th>
          <th scope="col" width="38%">{{ "Question" }}</th>
          <th scope="col" width="15%" class="text-center">
            {{ "Jurisdiction" }}
          </th>
          <th scope="col" width="15%" class="text-center">
            {{ "Created Date" }}
          </th>
          <th
            scope="col"
            width="15%"
            class="text-center"
            style="padding-right: 15px"
          >
            {{ "Created By" }}
          </th>
          <th
            scope="col"
            width="12%"
            class="text-center"
            style="padding-right: 20px"
            v-if="permissions.includes('nexlaw.legal-argument')"
          >
            {{ "Action" }}
          </th>
        </tr>
      </thead>

      <tbody>
        <PageLoader
          v-if="pageLoading"
          :loading="pageLoading"
        ></PageLoader>

        <div
          v-else-if="searched_data.length === 0"
          class="container_margin"
        >
          <div class="my-2 m-auto" style="width: 200px">
            <img src="@/assets/noData.png" alt="No Data Image" />
          </div>
          <p class="text-center text-blue font-weight-bold">
            {{ "No Data, Kindly compose a letter" }}
          </p>
        </div>

        <tr v-else v-for="(item, index) in searched_data"  :key="index">
          <td width="6%">{{ item.id }}</td>
          <td width="38%">{{ item.statement }}</td>
          <td width="15%" class="text-center">
            {{
              item.jurisdiction
                ? item.jurisdiction === "USA"
                  ? "United States of America"
                  : item.jurisdiction
                : "N/A"
            }}
          </td>
          <td width="15%" class="text-center text-muted">
            {{ item.created_at | formatDate }}
          </td>
          <td width="15%" class="text-center text-muted">
            <b>{{ item.creator.name | username }}</b>
          </td>
          <td
            width="12%"
            class="text-center"
            v-if="permissions.includes('nexlaw.legal-argument')"
          >
            <button
              class="btn btn-primary"
              @click="openCase(item.id, item)"
              v-if="item.completedPerCentage >= 100"
            >
              {{ "Open" }}
            </button>

            <button
              @click="openCase(item.id, item)"
              v-else
              class="btn btn-warning-outlined"
            >
              {{ Math.round(item.completedPerCentage) }} %
              <b-spinner label="Spinning" class="spinner" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end of History table -->

    <div style="height: 2.5rem;">
      <b-pagination
        v-if="searched_data.length !== 0 && rows > 10"
        class="float-right mt-3"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        last-number
      ></b-pagination>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import LegalWriter from "@/store/LegalWriter.js";
import Auth from "@/store/Auth.js";
import { Authenticate, getWordCount, groupValidateInput, loopUpdate } from "../../store/utils";

export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      extra_info: {
        contact_number: "",
        firm_address_1: "",
        firm_address_2: "",
        firm_address_3: "",
        company_name: "",
      },
      permissions: localStorage.permissions,
      info: JSON.parse(localStorage.userInfo),

      // statement form
      question: "",
      findings: "",
      jurisdiction: "",
      jurisdictions: [
        "United States of America",
        "United Kingdom",
        "Australia",
        "Canada",
        "New Zealand",
        "Singapore",
        "Malaysia",
      ],

      // history
      pageLoading: true,
      searched_data: [],
      searchQuery: [],
      currentPage: 1,
      rows: 100,
      perPage: 10,

      loop: false,
    };
  },

  methods: {
    getWordCount(text) { return getWordCount(text); },

    toastError(flag) {
      this.$toast.error({
        "empty": "Please fill in all required fields",
        "max": "Maximum word limit exceeded",
      }[flag]);
    },

    nextStep() {
      this.$validator.validateAll();

      const Results = groupValidateInput([
        [this.question, "string", ["max50"]],
        [this.findings, "string", ["max500"]],
        [this.jurisdiction, "jurisdiction"],
      ]);

      if (Results.findIndex(x => !x[0]) > -1) {
        this.toastError(Results.find(x => !x[0])[1]); return;
      }

      var obj = {
        statement: Results[0][1],
        findings: Results[1][1],
        jurisdiction: Results[2][1],
        type: "memo",
        answer: "yes", // is this really needed?
        location_id: JSON.parse(localStorage.locations).find(
          (x) => x.title == Results[2][1]
        ).id,
        language: "en",
      };

      // console.log(obj); return;
      LegalWriter.CreateLegalArgumentMemo(obj)
        .then((response) => {
          Authenticate(this);
          this.$router.push({
            name: "LegalResearchAISuggestion",
            params: {
              id: response.data.data.id,
            },
            query: {
              category: this.$route.query.category,
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onInput() {
      if (this.searchQuery.length === 0) {
        this.searchQuery = "";
        this.searchClients();
      }
    },

    searchClients() {
      this.currentPage = 1;
      this.pageLoading = true;
      this.searched_data = [];

      var queryObj = {
        page: this.currentPage,
        category: this.$route.query.category,
      };
      if (this.searchQuery.length !== 0) {
        queryObj.statement = this.searchQuery;
      }

      this.$router.push({ query: queryObj });
      this.loadData();
    },

    openCase(data, category) {
      this.$router.push({
        name: "LegalResearchAISuggestion",
        params: {
          id: data,
        },
        query: {
          category: this.$options.filters.composeType(category.type),
        },
      });
    },

    loadData() {
      const me = this;
      function processData(data) {
        const DATA = data.data.data;
        const insert = me.searched_data.length > 0;
        me.pageLoading = false;
        me.rows = DATA.total;
        DATA.data.forEach(e => {
          const ID = me.searched_data.findIndex(old => old.id === e.id);

          if (e.statement) {
            e.statement = e.statement.replace(/<[^>]*>/g, "");
          }

          if (ID > -1) {
            me.$set(me.searched_data, ID, e);
          } else if (insert) {
            me.searched_data.unshift(e);
          } else {
            me.searched_data.push(e)
          }

          if (e.completedPerCentage < 100) me.loop = true;
        })
      }

      loopUpdate(
        this,
        LegalWriter.GetLegalWriter,
        `?types[]=memo&page=${this.currentPage}${
          this.searchQuery ? `&statement=${this.searchQuery}` : ""
        }`,
        processData
      )
    },
  },

  watch: {
    currentPage(newPage, oldPage) {
      if (newPage != this.$route.query.page) {
        this.pageLoading = true;
        this.searched_data = [];

        var queryObj = {
          page: newPage,
          category: this.$route.query.category,
        };
        var param = `?types[]=memo&page=${newPage}`;
        if (this.searchQuery.length !== 0) {
          (queryObj.statement = this.searchQuery),
            (param += `&statement=${this.searchQuery}`);
        }

        this.$router.push({ query: queryObj });
        clearTimeout(this.loop);
        this.loadData();
      }
    },
  },

  created() {
    const page = this.$route.query.page;
    if (page) {
      this.currentPage = parseInt(page, 10);
      this.searched_data = [];
    }

    const statement = this.$route.query.statement;
    if (statement) {
      this.searchQuery = statement;
    }
    
    this.loadData();
  },

  destroyed() {
    clearTimeout(this.loop);
  },
};
</script>
<style scoped>
.container_margin {
  padding: 2rem;
}

.nexcard {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid lightgray;
  box-shadow: none;
}

/* table */
.table {
  border: none;
  background: white;
  table-layout: fixed;
}

tbody {
  display: block;
  max-height: auto;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}
/* table */

.spinner {
  color: #f08000 !important;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}
</style>
